import { put, call, select, takeEvery } from "redux-saga/effects";

import * as actions from "../actions";
import DatabaseService from "../DatabaseService";
import { getDiffInDays, kgToTon } from "../selectors/helpers";
import dayjs from "dayjs";
// import { getPendingBenchInputs } from "../selectors/data";

const db_daily_output = new DatabaseService("dash_daily_output_data_beta");
const db_monthly_output = new DatabaseService("dash_monthly_output_data_beta");
// const db_day_meta_output = new DatabaseService("dash_day_meta_output_data");
// const db_stream_meta_output = new DatabaseService(
//   "dash_stream_meta_output_data"
// );
const db_output_streams = new DatabaseService("cache_sp_output_streams");
const db_input_streams = new DatabaseService("cache_sp_input_streams");

const getDateRange = (state) => state.data.dateRange;

export function* getOuputStreams(action) {
  // console.log("SAGA > getOuputStreams", action);
  const output_streams = yield call(db_output_streams.getAll);

  if (output_streams && output_streams.length > 0) {
    // console.log("SAGA > output_streams > post getAll", output_streams);
    yield put(actions.receivetOutputStreams(output_streams));
  }
}

// export function* getInputStreams(action) {
//   // console.log("SAGA > getOuputStreams", action);
//   const input_streams = yield call(db_input_streams.getAll);

//   if (input_streams && input_streams.length > 0) {
//     console.log("SAGA > input_streams > post getAll", input_streams);
//     yield put(actions.receiveInputStreams(input_streams));
//   }
// }

export function* clearFilters(action) {
  console.log("SAGA > clearFilters ", action);
  yield put(actions.setOuputDateFilters([]));
}

export function* dateRangeChange(action) {
  console.log("SAGA > dateRangeChange ", action);

  // yield put(actions.requestOutputDailyData());
  // yield put(actions.requestInputData());
}

export function* getOutputDailyData(action) {
  // const dateRange = yield select(getDateRange);

  console.log(
    "MIDDLE > getOutputDailyData ",
    action.data.start,
    dayjs(action.data.start).tz().subtract(1, "week").valueOf()
  );

  const outputDailyData = yield call(db_daily_output.query, {
    index: ["date"],
    query: {
      selector: {
        date: {
          $gte: action.data.start,
          $lte: action.data.end,
          // $gte: dayjs(action.data.start).tz().subtract(1, "week").valueOf(),
          // $lte: dayjs(action.data.end).tz().add(1, "week").valueOf(),
        },
      },
      sort: [{ date: "desc" }],
    },
  });

  // const outputDailyData = yield call(db_daily_output.getAll);
  if (outputDailyData && outputDailyData.length > 0) {
    outputDailyData.forEach((o) => {
      o.weight = kgToTon(o.weight);
      o.source.commercial.weight = kgToTon(o.source.commercial.weight);
      o.source.residential.weight = kgToTon(o.source.residential.weight);
      o.source.unknown.weight = kgToTon(o.source.unknown.weight);
    });

    yield put(actions.receiveOutputDailyData(outputDailyData));
  }
}

export function* getOutputMonthlyData(action) {
  const outputMonthlyData = yield call(db_monthly_output.getAll);
  if (outputMonthlyData && outputMonthlyData.length > 0) {
    outputMonthlyData.sort((a, b) => a.completed > b.completed);
    outputMonthlyData.forEach((o) => {
      o.weight = kgToTon(o.weight);
      o.source.commercial.weight = kgToTon(o.source.commercial.weight);
      o.source.residential.weight = kgToTon(o.source.residential.weight);
      o.source.unknown.weight = kgToTon(o.source.unknown.weight);

      o.day_meta.forEach((dm) => {
        dm.max = kgToTon(dm.max);
        dm.total = kgToTon(dm.total);
        dm.source.commercial.weight = kgToTon(dm.source.commercial.weight);
        dm.source.residential.weight = kgToTon(dm.source.residential.weight);
        dm.source.unknown.weight = kgToTon(dm.source.unknown.weight);
      });
    });

    yield put(actions.receiveOutputMonthlyData(outputMonthlyData));
  }
}

// export function* getDayMetaData(action) {
//   const dayMetaData = yield call(db_day_meta_output.getAll);
//   if (dayMetaData && dayMetaData.length > 0) {
//     yield put(actions.receiveDayMetaData(dayMetaData));
//   }
// }

// export function* getStreamMetaData(action) {
//   const streamMetaData = yield call(db_stream_meta_output.getAll);
//   if (streamMetaData && streamMetaData.length > 0) {
//     yield put(actions.receiveStreamMetaData(streamMetaData));
//   }
// }

export function* checkDateRange(action) {
  let diff = getDiffInDays(action.data.start, action.data.end);

  console.log("MIDDLE > checkDateRange ", diff, action);

  if (diff < 62) {
    yield put(actions.requestOutputDailyData(action.data));
  }
}

export function* clearDateFilter(action) {
  yield put(actions.receiveOutputDailyData([]));
  yield put(actions.setOuputDateFilters([]));
}

export function* getInputStreams(action) {
  // console.log("SAGA > getOuputStreams", action);
  const input_streams = yield call(db_input_streams.getAll);

  if (input_streams && input_streams.length > 0) {
    console.log("SAGA > input_streams > post getAll", input_streams);
    yield put(actions.receiveInputStreams(input_streams));
  }
}

export function* watch() {
  yield takeEvery(actions.REQUEST_INPUT_STREAMS, getInputStreams);
  yield takeEvery(actions.REQUEST_OUTPUT_STREAMS, getOuputStreams);
  yield takeEvery(actions.REQUEST_OUTPUT_DAILY_DATA, getOutputDailyData);
  yield takeEvery(actions.REQUEST_OUTPUT_MONTHLY_DATA, getOutputMonthlyData);
  // yield takeEvery(actions.REQUEST_DAY_META_DATA, getDayMetaData);
  // yield takeEvery(actions.REQUEST_STREAM_META_DATA, getStreamMetaData);
  yield takeEvery(actions.SET_DATE_RANGE, dateRangeChange);
  yield takeEvery(actions.SET_DATE_RANGE, checkDateRange);
  yield takeEvery(actions.SET_OUTPUT_DATE_FILTERS, checkDateRange);
  yield takeEvery(actions.ADD_OUTPUT_DATE_FILTER, checkDateRange);
  yield takeEvery(actions.REMOVE_OUTPUT_DATE_FILTER, clearDateFilter);
  // yield takeEvery(actions.RECEIVE_OUTPUT_DATA, clearFilters);
}
